import type {App} from "vue";
import {until} from "@vueuse/core";
import {Logger} from "@/app/logger";
import {createRouter as createVueRouter, createWebHistory, type Router} from "vue-router";
import CreateBooking from "@/pages/BookingCreate.vue";
import Causes from "@/pages/Causes.vue";
import ForgotPassword from "@/pages/ForgotPassword.vue";
import HelpInformationBase from "@/pages/HelpInformationBase.vue";
import Home from "@/pages/Home.vue";
import Messages from "@/pages/Messages.vue";
import Process from "@/pages/Process.vue";
import SignIn from "@/pages/SignIn.vue";
import FinishBooking from "@/pages/account-children/BookingFinish.vue";
import AuthBase from "@/pages/auth/Base.vue";
import Callback from "@/pages/auth/Callback.vue";
import HelpInformation from "@/pages/help-information-children/HelpInformation.vue";
import TermsConditionsInsurance from "@/pages/help-information-children/TermsConditionsInsurance.vue";
import {useSession} from "@/app/stores/session";
import {storeToRefs} from "pinia";

const AccountBooking = () => import("@/pages/account-children/Booking.vue");

const DEFAULT_TITLE = "PrettyShell";

export interface EmailToOps {
  address: string;
  subject: string;
  body?: string;
  cc?: string;
}

export const withEmailTo = (options: EmailToOps) => {
  const u = (s: string | undefined) => (s ? encodeURIComponent(s) : "");
  return `mailto:${options.address}?subject=${u(options.subject)}&body=${u(options.body)}`;
};

export const routes = [
  {
    name: "home",
    path: "/",
    component: Home,
    meta: {
      layout: "home",
    },
  },
  {
    name: "auth",
    path: "/auth",
    component: AuthBase,
    meta: {
      breadcrumb: {
        label: "Sign-in",
      },
    },
    children: [
      {
        name: "authCallback",
        path: "callback",
        component: Callback,
        props: (route) => ({
          errorType: route.query.error,
          errorDescription: route.query.error_description,
        }),
      },
      {
        name: "signInHome",
        path: "signin",
        component: Home,
        meta: {
          layout: "home",
        },
      },
    ],
  },
  {
    path: "/causes",
    name: "causes",
    component: Causes,
    meta: {
      breadcrumb: {
        label: "Causes",
      },
    },
  },
  {
    path: "/process",
    name: "process",
    component: Process,
    meta: {
      breadcrumb: {
        label: "Process",
      },
    },
  },
  {
    name: "messages",
    path: "/messages",
    meta: {
      withFooter: false,
      withActionNav: false,
      requiresAuthenticated: true,
    },
    component: Messages,
  },
  {
    name: "chat",
    path: "/messages/:chatId",
    meta: {
      withFooter: false,
      withActionNav: false,
      requiresAuthenticated: true,
    },
    props: true,
    component: Messages,
  },
  {
    name: "bookingCreated",
    path: "/bookings/:bookingId/:initialState",
    component: CreateBooking,
    meta: {
      requiresAuthenticated: true,
      breadcrumb: {
        before: [
          {
            name: "bookingCreated",
            label: "Request booking",
            params: {initialState: "created"},
          },
        ],
        label: (params) =>
          ({
            payment: "Choose payment method",
            created: "Insurance",
            confirm: "Confirm booking request",
            cause: "Choose a cause",
          })[params.initialState],
      },
    },
    props: true,
  },
  {
    name: "bookingSuccess",
    path: "/bookings/:bookingId/success",
    component: FinishBooking,
    props: (route) => ({
      bookingId: route.params.bookingId,
    }),
    meta: {
      requiresAuthenticated: true,
    },
  },
  {
    path: "/help-information",
    component: HelpInformationBase,
    meta: {
      breadcrumb: {
        label: "Help & Information",
      },
    },
    children: [
      {
        name: "helpInformation",
        path: "",
        component: HelpInformation,
      },
      {
        name: "termsConditionsInsurance",
        path: "terms-conditions-insurance",
        component: TermsConditionsInsurance,
        meta: {
          breadcrumb: {
            label: "Pretty Shell Insurance",
          },
        },
      },
    ],
  },
  {
    name: "signIn",
    path: "/signin",
    component: SignIn,
  },
  {
    name: "forgotPassword",
    path: "/forgot-password",
    component: ForgotPassword,
    props: true,
  },
];
export const createRouter = () => {
  Logger.info("Creating router");
  const router = createVueRouter({
    scrollBehavior(to) {
      if (to.hash) {
        return {
          el: to.hash,
          behavior: "smooth",
        };
      }
      // always scroll to top
      return {top: 0};
    },
    history: createWebHistory(),
    routes,
    strict: true,
  });

  router.beforeResolve(async (to) => {
    if (to.meta.withActionNav === undefined) {
      to.meta.withActionNav = true;
    }
    if (to.meta.breadcrumb) {
      if (to.meta.breadcrumb.deps) {
        to.meta.context = {};
        await Promise.all(
          Object.entries(to.meta.breadcrumb.deps).map(async (obj) => {
            to.meta.context[obj[0]] = await obj[1](to.params);
          }),
        );
      }
    }
  });

  router.beforeEach(async (to) => {
    const session = useSession();
    await until(session).not.toBeUndefined();
    const {isAuthenticated, isReady} = storeToRefs(session);
    try {
      Logger.info("Waiting for session to be ready");
      await until(isReady).toBe(true, {timeout: 5000});
    } catch (err) {
      Logger.error(err);
    }
    if (to.meta.requiresAuthenticated) {
      if (isAuthenticated.value) {
        return true;
      }
      Logger.info("🛡 Redirecting to sign-in");
      return {
        name: "home",
      };
    }
  });

  router.afterEach((to) => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });

  return router;
}

const AppRouter = {
  install(app: App) {
    this.router = createRouter();
    app.use(this.router);
  },
} as {
  router: Router;
  install: (app: App) => void;
};

export default AppRouter
