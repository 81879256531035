import {RouteFactory} from "@/app/router";
import SignUp from "@/pages/signup/SignUp.vue";
import SignUpConfirmPage from "@/pages/signup/SignUpConfirmPage.vue";
import SignUpConfirmedPage from "@/pages/signup/SignUpConfimedPage.vue";
import SignUpBasePage from "@/pages/signup/SignUpBasePage.vue";

const factory = new RouteFactory();

factory.addRoute({
  name: 'signupBase',
  path: '/signup',
  component: SignUpBasePage,
});

factory.addSubRoute(
  'signupBase',
  {
    name: 'signupEntry',
    path: '',
    component: SignUp,
  }
);

factory.addSubRoute(
  'signupBase',
  {
    name: 'signupConfirm',
    path: 'confirm',
    component: SignUpConfirmPage,
  }
);

factory.addSubRoute(
  'signupBase',
  {
    name: 'signupConfirmed',
    path: 'confirmed',
    component: SignUpConfirmedPage,
  }
);

export const routes = factory.routes;
