<template>
  <section class="is-stacked">
    <h3>Email confirmed</h3>
    <p>Your email has been confirmed. You can now sign-in.</p>
  </section>
</template>

<script setup lang="ts">

</script>
