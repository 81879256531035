import type {RouteParamsGeneric, Router, RouteRecordRaw} from 'vue-router';
import type {Component} from "vue";

export type Params = RouteParamsGeneric;
export type Context = Record<string, unknown>

export type IdExtractor<P extends Params> = (params: P) => string;
type Getter<P extends Params, C extends Context> = (params: P) => Promise<C[keyof C] | undefined>
export type GetterFactory<P extends Params, C extends Context> = (paramKey: keyof P) => Getter<P, C>
type Deps<P extends Params, C extends Context> = Record<keyof C, Getter<P, C>>

type LabelSpec<P extends Params, C extends Context> = ((params: P, context: C) => string | null) | string;
type ShowSpec<P extends Params, C extends Context> = (params: P, context: C) => boolean;
type LinkParamsSpec<P extends Params, C extends Context> = (params: P, context: C) => Record<string, string>;

export interface Breadcrumb <P extends Params=Params, C extends Context=Context>{
  deps?: Deps<P, C>;
  label?: LabelSpec<P, C>;
  name?: string;
  before?: {
    label?: LabelSpec<P, C>;
    name?: string;
    show?: ShowSpec<P, C>;
    params?: LinkParamsSpec<P, C>;
  }[]
}

export interface Route<P extends Params=Params, C extends Context=Context> {
  path: string;
  name: string;
  component: Component;
  children?: Route[];
  title?: string;
  withFooter?: boolean;
  withBreadcrumb?: Breadcrumb<P, C>;
  requiresAuthenticated?: boolean;
  props?: RouteRecordRaw['props'];
}

export type SubRoute = [string, Route];

declare module "vue-router" {
  interface RouteMeta {
    // must be declared by every route
    requiresAuthenticated: boolean;
    title?: string;
    breadcrumb?: Breadcrumb;
    context?: Context;
  }
}

export const routeToRouteRecord = <P extends Params, C extends Context>(route: Route<P, C>, defaults: Partial<Route<P, C>>): RouteRecordRaw => {
  const thisRoute: Route<P, C> = {...defaults, ...route};
  return {
    path: thisRoute.path,
    name: thisRoute.name,
    component: thisRoute.component,
    children: thisRoute.children ? thisRoute.children.map(child => routeToRouteRecord(child, defaults)) : [],
    props: thisRoute.props,
    meta: {
      title: thisRoute.title,
      breadcrumb: thisRoute.withBreadcrumb,
      withFooter: thisRoute.withFooter,
      requiresAuthenticated: thisRoute.requiresAuthenticated ?? false,
    },
  }
}

export const createRoutes = (routes: Route[], defaults?: Partial<Route> | undefined): RouteRecordRaw[] => {
  return routes.map(route => {
    return routeToRouteRecord(route, defaults ?? {});
  })
};

export class RouteFactory {
  public routes: (RouteRecordRaw | [string, RouteRecordRaw])[] = [];
  public defaults: Partial<Route> = {};

  addRoute<P extends Params, C extends Context>(route: Route<P, C>, defaults?: Partial<Route>) {
    this.routes.push(routeToRouteRecord<P, C>(route, defaults ?? this.defaults));
  }

  addSubRoute<P extends Params, C extends Context>(parent: string, route: Route<P, C>, defaults?: Partial<Route>) {
    this.routes.push([parent, routeToRouteRecord<P, C>(route, defaults ?? this.defaults)]);
  }
}

export const addRoutesToRouter = (router: Router, routes: RouteFactory['routes']) => {
  for (const route of routes) {
    if (Array.isArray(route)) {
      router.addRoute(...route);
    } else {
      router.addRoute(route);
    }
  }
}

