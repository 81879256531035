<template>
  <div class="page is-centered">
    <router-view />
  </div>
</template>

<script setup lang="ts">

</script>

<style scoped lang="scss">

</style>
