<template>
  <section class="is-stacked">
    <h3>Verify your email</h3>

    <p>We've sent an email with a verification code. Please check your inbox and fill the code below.</p>

    <FormKit id="confirmSignUp" submit-label="Verify my email" type="form" @submit="submit" v-model="formData" :errors="errors">
      <div class="field">
        <FormKit label="Email" name="email" placeholder="Email" type="email" validation="required"
                 validation-label="Email address"/>
      </div>

      <div class="field">
        <FormKit name="token" placeholder="Verification code" type="number" label="Verification code"
                 validation="required|number" validation-label="Verification code"/>
      </div>
    </FormKit>
    <div class="field">
      <PsButton class="is-fullwidth" color="secondary" @click.prevent="resend">Resend code</PsButton>
      <p class="help">{{ tokenHelp }}</p>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {useRoute, useRouter} from "vue-router";
import {FormKit} from "@formkit/vue";
import {useSession} from "@/app/stores/session";
import {ref} from "vue";

const route = useRoute();
const router = useRouter();
const session = useSession();

const formData = ref({
  email: route.query.email,
  token: route.query.code,
})
const errors = ref([]);
const tokenHelp = ref<string | undefined>(undefined);

const setError = (error?: string) => {
  if (!error) {
    errors.value = [];
  } else {
    errors.value = [error];
  }
}

const submit = async (values: {email: string, token: string}) => {
  const result = await session.verifyEmail(
    values.email,
    values.token,
  )
  if (!result.isConfirmed) {
    setError(result.errorMsg ?? 'Something went wrong.');
  } else {
    errors.value = [];
    await router.push({name: "signupConfirmed"});
  }
}

const resend = async () => {
  if (!formData.value.email) {
    tokenHelp.value = 'Please enter your email address.';
    return
  }
  await session.resendSignUpCode(formData.value.email as string);
  tokenHelp.value = 'A new verification code has been sent to your email.';
  setTimeout(() => {
    tokenHelp.value = undefined;
  }, 1500);
}
</script>

<style lang="scss" scoped>

</style>
