<template>
  <article v-if="step == 'enter'">
    <ForgotPassword
      :loading="loading"
      @forgot-password="handleForgotPassword($event)"
    />
    <div class="field">
      <span
        v-if="error"
        class="help is-error"
      >{{ error }}</span>
    </div>

    <span class="hr">OR</span>

    <div class="field">
      <PsButton
        class="is-fullwidth"
        color="secondary"
        styling="outlined"
        @click="skipToCodeStep"
      >
        Fill in verification code
      </PsButton>
    </div>
  </article>
  <article v-else-if="step == 'code'">
    <p class="py-4">
      We've sent an email with a verification code. Please check your inbox and fill in the code
      below.
    </p>

    <ConfirmPassword
      :email="email"
      :loading="loading"
      @confirm-password="handleConfirmPassword($event)"
    />
    <div class="field">
      <span
        v-if="errorConfirm"
        class="help is-error"
      >{{ errorConfirm }}</span>
    </div>
  </article>

  <article v-else-if="step == 'success'">
    <p>
      Your password has been changed, you can now
      <a @click="goToSignIn">sign in</a>
    </p>
  </article>

  <div class="field has-text-centered mt-4">
    <a
      class="is-hidden-tablet link mx-auto has-text-primary"
      @click="router.push({ name: 'signIn' })"
    >Sign in instead</a>
    <a
      class="is-hidden-mobile link mx-auto has-text-primary"
      @click="showSignIn"
    >Sign in instead</a>
  </div>

  <hr>

  <div class="level">
    <div class="level-left">
      <div class="level-item">
        <p>Don't have an account yet?</p>
      </div>
    </div>
    <div class="level-right">
      <div class="level-item">
        <a
          class="is-hidden-tablet link has-text-primary"
          @click="router.push({ name: 'signUp' })"
        >Sign up</a>
        <a
          class="is-hidden-mobile link has-text-primary"
          @click="showSignUp"
        >Sign up</a>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {inject, ref} from "vue";
import {useRoute, useRouter} from "vue-router";

import ConfirmPassword from "@/forms/ConfirmPassword.vue";
import ForgotPassword from "@/forms/ForgotPassword.vue";

import { useAuth } from "@/app/auth";

const route = useRoute();

const props = defineProps({
  isActive: {
    type: Boolean,
    default: false,
  },
  initialStep: {
    type: String,
    default: undefined,
  },
});

const auth = useAuth();
const router = useRouter();

const email = ref("");
const step = ref(props.initialStep ?? route.query.step ?? "enter");
const error = ref(null);
const errorConfirm = ref(null);
const loading = ref(false);
const { setModal } = inject('authOverlay');

function skipToCodeStep() {
  step.value = "code";
}

function showSignIn() {
  setModal("signIn");
}

function showSignUp() {
  setModal("signUp");
}

async function handleForgotPassword({ formData }) {
  loading.value = true;
  error.value = null;

  try {
    email.value = formData.email;
    await auth.forgotPassword({
      username: formData.email,
    });
    step.value = "code";
  } catch (err) {
    error.value = err.message;
  } finally {
    loading.value = false;
  }
}

async function handleConfirmPassword({ formData }) {
  loading.value = true;
  errorConfirm.value = null;

  try {
    await auth.changePasswordByVerificationCode(
      formData.email,
      formData.verificationCode,
      formData.password,
    );
    step.value = "success";
  } catch (err) {
    errorConfirm.value = err.message;
  } finally {
    loading.value = false;
  }
}
</script>

<style lang="scss" scoped></style>
