import {
  confirmResetPassword,
  fetchAuthSession,
  resetPassword,
  signIn,
  resendSignUpCode,
  type SignInOutput,
  signInWithRedirect,
  signOut,
  signUp,
  confirmSignUp
} from "aws-amplify/auth";
import {passwordStrength} from "check-password-strength";
import {type App, inject} from "vue";
import {Logger} from "@/app/logger";

import { Amplify } from "aws-amplify";

import awsconfig from "@/config.aws.js";
import {AuthInjectionKey} from "@/plugins/symbols";

export type SignInOpts = {
  username: string;
  password: string;
};

export type User = {
  username: string;
};

export interface SignUpOpts {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
}

export interface ForgetPasswordOpts {
  username: string;
}

export enum SignInFlow {
  email = 0,
  google = 1,
  apple = 2,
  facebook = 3,
}

export enum SignUpFlow {
  email = 0,
}

export type AuthServiceOpts = {
  app: object;
};

class AuthError extends Error {
  constructor(name: string, message: string) {
    super(message);
    this.name = name;
  }
}

interface EmailVerificationResult {
  isConfirmed: boolean;
  errorMsg?: string;
}

class AuthService {
  app: object;

  constructor(options: AuthServiceOpts) {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: awsconfig.cognito.USER_POOL_ID,
          userPoolClientId: awsconfig.cognito.APP_CLIENT_ID,
          identityPoolId: awsconfig.cognito.IDENTITY_POOL_ID,
          allowGuestAccess: false,
          loginWith: {
            oauth: {
              domain: import.meta.env.VITE_APP_OAUTH_DOMAIN,
              scopes: ["email", "openid"],
              redirectSignIn: [awsconfig.cognito.REDIRECT_URL],
              redirectSignOut: [awsconfig.cognito.SIGN_OUT_URL],
              responseType: "code",
            },
          },
        },
      },
    });
    this.app = options.app;
  }

  handleSignInError(err: Error) {
    switch (err.message.toString()) {
      case "Username cannot be empty.":
      case "Incorrect username or password.":
        throw new AuthError("InvalidCredentials", err.message);
      case "User is not confirmed.":
        throw new AuthError(
          "UserNotConfirmed",
          "Given address has not been confirmed yet, please check your email.",
        );
    }
  }

  handleSignUpError(err: Error) {
    switch (err.message) {
      case "User already exists":
        throw new AuthError("UsernameAlreadyExists", err.message);
      default:
        throw new AuthError("Unexpected error", "");
    }
  }

  async acquireSession() {
    try {
      const session = await fetchAuthSession();
      if (typeof session === "object" && session.tokens) {
        return {
          type: "authenticated",
          result: session,
        }
      }
    } catch (err) {
      Logger.error(err);
    }
    return {
      type: "unauthenticated",
    }
  }

  async init() {
  }

  async signIn(options: SignInOpts) {
    let response: SignInOutput | undefined = undefined;
    try {
      response = await signIn({
        username: options.username,
        password: options.password,
      });
      if (response.isSignedIn) {
        return true
      }
    } catch (error: unknown) {
      this.handleSignInError(error as Error);
      console.log(error);
    }
    if (response && response.nextStep.signInStep === "CONFIRM_SIGN_UP") {
      throw new AuthError(
        "EmailNotConfirmed",
        "Given address has not been confirmed yet, please check your email inbox for further instructions.",
      );
    }
    throw new AuthError("InvalidSession", "No valid session active");
  }

  async signInGoogle() {
    await signInWithRedirect({provider: "Google"});
  }

  async signInApple() {
    await signInWithRedirect({provider: "Apple"});
  }

  async signInFacebook() {
    await signInWithRedirect({provider: "Facebook"});
  }

  async signOut() {
    try {
      await signOut();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  checkPassword(password: string) {
    const result = passwordStrength(password);
    return ["Medium", "Strong"].indexOf(result.value) > -1 && result.length > 7;
  }

  async signUp(params: SignUpOpts) {
    try {
      await signUp({
        username: params.email,
        password: params.password,
        options: {
          userAttributes: {
            email: params.email,
            "custom:firstName": params.firstName,
            "custom:lastName": params.lastName,
          },
        },
      });
    } catch (error: unknown) {
      this.handleSignUpError(error as Error);
    }
  }

  async verifyEmail(email: string, code: string): Promise<EmailVerificationResult> {
    try {
      const result = await confirmSignUp({
        username: email,
        confirmationCode: code,
      });
      return {
        isConfirmed: true
      };
    } catch (error: unknown) {
      return {
        isConfirmed: false,
        errorMsg: error.message
      };
    }
  }

  async resendVerificationEmail(email: string) {
    return await resendSignUpCode({
      username: email,
    })
  }

  expire() {
    return this.acquireSession();
  }

  async forgotPassword(params: ForgetPasswordOpts) {
    await resetPassword({username: params.username});
  }

  async changePasswordByVerificationCode(
    username: string,
    code: string,
    password: string,
  ) {
    await confirmResetPassword({
      username,
      newPassword: password,
      confirmationCode: code,
    });
  }
}

export default {
  install(app: App) {
    /* install authentication handlers in app */
    const handlers = new AuthService({app});
    app.config.globalProperties.$auth = handlers;
    app.provide("auth", handlers);
    app.provide(AuthInjectionKey, handlers);
  },
};

export type IAuth = AuthService;

export function useAuth(): IAuth {
  const result = inject<IAuth>(AuthInjectionKey);
  if (!result) throw "Misconfigured, no Auth";
  return result;
}
