import { createApp, type App as VueApp } from "vue";
import AppComponent from "@/App.vue";
import { withEventBus, bus, type EventBus } from "@/app/eventBus";
import type { Router } from "vue-router";
import { useIdle } from "@vueuse/core";
import { watch } from "vue";

import Auth from "@/app/auth";
import { AppActiveToIdle, AppIdleToActive } from "@/app/events";

import Vue3Toastify, { type ToastContainerOptions } from 'vue3-toastify';
import {useDirectives} from "@/app/directives";
import {addRoutesToRouter} from "@/app/router";
import {routes} from "./routes";


const APP_IDLE_TIMEOUT = 1000 * 60 * 5;

const App = createApp(AppComponent);

export interface DomainCbOpts {
  router: Router,
  bus: EventBus,
  app: VueApp,
}

App.use(withEventBus);
App.use(Auth);
App.use(Vue3Toastify, {
  autoClose: 3000,
  position: "bottom-center",
})
App.use(useDirectives)

export const createDomain = (domain: string, cb: (opts: DomainCbOpts) => void) => {
  return cb;
}
export const registerAppDomain = createDomain("app", ({ bus, router }) => {
  const { idle } = useIdle(APP_IDLE_TIMEOUT, {initialState: true});
  watch(idle, (idleValue) => {
    if (idleValue) {
      bus.publish(AppActiveToIdle());
    } else {
      bus.publish(AppIdleToActive());
    }
  })

  addRoutesToRouter(router, routes);
});

export { App, bus };
